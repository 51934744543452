export const Endpoints = {
  auth: {
    login: '/auth/login',
    logout: '/auth/logout',
    forgotPassword: '/auth/forgot-password',
    resetPassword: '/auth/reset-password',
  },

  administrator: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/admins?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/admins',
    update: (id: string) => {
      return `/admins/${id}`;
    },
    delete: (id: string) => {
      return `/admins/${id}`;
    },
    changePassword: (id: string) => {
      return `/admins/${id}/change-password`;
    },
  },

  common: {
    commonData: '/common/data',
    branchList: '/branches',
    departmentList: '/departments',
    designationList: '/designations',
    employeesList: '/employees',
    companyList: '/companies',
    branchespatientEmp: '/branchespatientEmp',
    taskList: '/scheduled-task',
    addTaskList: (id: any) => {
      return `/scheduled-task`;
    },
    updateTask: (id: any) => {
      return `/scheduled-task/${id}/taskStatus`;
    },
    messageCenter: () => {
      return `/message-center`;
    },
    messageCenterResponse: () => {
      return `/message-reply`;
    },
    employeesSchduledTask: () => {
      return '/emp-work-scheduled';
    },
  },

  company: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/companies?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/companies',
    detail: (id: string) => {
      return `/companies/${id}`;
    },
    update: (id: string) => {
      return `/companies/${id}`;
    },
    delete: (id: string) => {
      return `/companies/${id}`;
    },
  },

  brnach: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/branches?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/branches',
    detail: (id: string) => {
      return `/branches/${id}`;
    },
    update: (id: string) => {
      return `/branches/${id}`;
    },
    delete: (id: string) => {
      return `/branches/${id}`;
    },
  },

  department: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/departments?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/departments',
    update: (id: string) => {
      return `/departments/${id}`;
    },
    delete: (id: string) => {
      return `/departments/${id}`;
    },
  },

  designation: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/designations?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/designations',
    update: (id: string) => {
      return `/designations/${id}`;
    },
    delete: (id: string) => {
      return `/designations/${id}`;
    },
  },

  employees: {
    list: (page: number, limit: number, search: string, filter: number) => {
      return (
        `/employees?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '') +
        (filter ? `&terminated=${filter}` : '')
      );
    },
    add: '/employees',
    detail: (id: string) => {
      return `/employees/${id}`;
    },
    update: (id: string) => {
      return `/employees/${id}`;
    },
    delete: (id: string) => {
      return `/employees/${id}`;
    },
    branchList: (type: string) => {
      return `/branches?type=${type}`;
    },
    departmentList: (type: string) => {
      return `/departments?type=${type}`;
    },
    profession: (type: string) => {
      return `/employees?profession=${type}`;
    },
    reSechdule: (empId) => {
      return `/emp-customised-scheduled/${empId}`;
    },
  },

  patient: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/patient?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/patient',
    detail: (id: string) => {
      return `/patient/${id}`;
    },
    update: (id: string) => {
      return `/patient/${id}`;
    },
    delete: (id: string) => {
      return `/patient/${id}`;
    },
    addClaim: (id: string) => {
      return `/patient-claim/${id}`;
    },
    updateClaim: (id: string) => {
      return `/update-claim/${id}`;
    },
    claimList: (
      page: number,
      limit: number,
      search: string,
      claimType: string
    ) => {
      return (
        `/patient-claim-list?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '') +
        (claimType ? `&claimType=${claimType}` : '')
      );
    },
    dcClaimUpdateStatus: (patientId) => {
      return `/dc-status-claim/${patientId}`;
    },
    dcList: () => {
      return `/dc-tracker`;
    },
    dcTrackerPatientFollowupData: (patientId, claimId) => {
      return (
        `/dc-tracker-followup` +
        (patientId ? `?patientId=${patientId}&claimId=${claimId}` : '')
      );
    },
    dcAddComments: () => {
      return `/dc-tracker-followup`;
    },
    denialTrackerAdd: () => {
      return `/denial-tracker`;
    },
    getDenialTracker: () => {
      return `/denial-tracker`;
    },
    getDenialTrackerDetail: (patientId, claimId) => {
      return `/denial-tracker/${patientId}/${claimId}`;
    },
    addDenialComment: (patientId, claimId) => {
      return `/denial-tracker-add-comment/${patientId}/${claimId}`;
    },
  },
  clinician_notes: {
    list: (page?: number, limit?: number, search?: string) => {
      return (
        `/clinician-notes-list?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/patient',
    detail: (id: string) => {
      return `/clinician-notes-list/${id}`;
    },
    updateStatus: (id: string) => {
      return `/clinician-notes-list/${id}/status`;
    },
    claimList: (
      page: number,
      limit: number,
      search: string,
      claimType: string
    ) => {
      return (
        `/patient-claim-list?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '') +
        (claimType ? `&claimType=${claimType}` : '')
      );
    },
    dcClaimUpdateStatus: (patientId) => {
      return `/dc-status-claim/${patientId}`;
    },
    dcList: () => {
      return `/dc-tracker`;
    },
    dcTrackerPatientFollowupData: (patientId, claimId) => {
      return (
        `/dc-tracker-followup` +
        (patientId ? `?patientId=${patientId}&claimId=${claimId}` : '')
      );
    },
    dcAddComments: () => {
      return `/dc-tracker-followup`;
    },
  },
  providers: {
    list: (id: string) => {
      return `/employees-fds-branch?branchId=${id}`;
    },
  },

  managerialTitle: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/managerial-titles?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/managerial-titles',
    update: (id: string) => {
      return `/managerial-titles/${id}`;
    },
    delete: (id: string) => {
      return `/managerial-titles/${id}`;
    },
  },
  errorList: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/error-list?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/error-list',
    update: (id: string) => {
      return `/error-list/${id}`;
    },
    delete: (id: string) => {
      return `/error-list/${id}`;
    },
  },
  errorType: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/error-type?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/error-type',
    update: (id: string) => {
      return `/error-type/${id}`;
    },
    delete: (id: string) => {
      return `/error-type/${id}`;
    },
  },
  actionType: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/action-type?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/action-type',
    update: (id: string) => {
      return `/action-type/${id}`;
    },
    delete: (id: string) => {
      return `/action-type/${id}`;
    },
  },
  errorTracker: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/error-tracking?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/error-tracking',
    update: (id: string) => {
      return `/error-tracking/${id}`;
    },
    delete: (id: string) => {
      return `/error-tracking/${id}`;
    },
    status: (id: string) => {
      return `/error-tracking/${id}/status`;
    },
    addrecipient: (id: string) => {
      return `/error-tracking/${id}/addrecipient`;
    },
  },

  inventory: {
    list: (
      page: number,
      limit: number,
      search: string,
      inventoryType: string
    ) => {
      return (
        `/inventory?page=${page}&limit=${limit}&inventoryType=${inventoryType}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/inventory',
    update: (id: string) => {
      return `/inventory/${id}`;
    },
    delete: (id: string) => {
      return `/inventory/${id}`;
    },
    'fds-inventory': (search: string) => {
      if (search && search.length > 0) {
        return `/fds-inventory?page=1&limit=1000&search=${search}`;
      } else {
        return `/fds-inventory?page=1&limit=1000`;
      }
    },
  },
  companyType: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/company-types?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/company-types',
    update: (id: string) => {
      return `/company-types/${id}`;
    },
    delete: (id: string) => {
      return `/company-types/${id}`;
    },
  },

  performance: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/performances?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/performances',
    detail: (id: string) => {
      return `/performances/${id}`;
    },
    update: (id: string) => {
      return `/performances/${id}`;
    },
    delete: '/performances/bulk-delete',
    jobs: (id: string) => {
      return `/employees/${id}/jobs`;
    },
    employees: (id: string) => {
      return `/employees?branchId=${id}`;
    },
  },

  performanceSummary: {
    list: (period: string) => {
      return `/performances/summary?period=${period}`;
    },
    add: '/performances/summary',
    update: (id: string) => {
      return `/performance-summary/${id}`;
    },
    delete: (id: string) => {
      return `/performance-summary/${id}`;
    },
    details: (
      page: number,
      limit: number,
      id,
      period: string,
      search: string
    ) => {
      return (
        `/performances?page=${page}&limit=${limit}&employeeId=${id}&period=${period}` +
        (search ? `&search=${search}` : '')
      );
    },
  },

  supplyOrder: {
    patientOrderList: (
      page: number,
      limit: number,
      search: string,
      branchId
    ) => {
      return (
        `/patient-order?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '') +
        (branchId ? `&branchId=${branchId}` : '')
      );
    },
    patientOrder: '/patient-order',
    // "patientOrderStatus": (id: string) => { return `/patient-order/${id}` },
    patientOrderStatus: '/patient-order',
    delete: (id: string) => {
      return `/patient-order/${id}`;
    },
    offiecOrderList: (
      page: number,
      limit: number,
      search: string,
      branchId
    ) => {
      return (
        `/office-order?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '') +
        (branchId ? `&branchId=${branchId}` : '')
      );
    },
    offiecOrder: '/office-order',
    // "officeOrderStatus": (id: string) => { return `/patient-order/${id}` },
    officeOrderStatus: '/office-order',
    requestProduct: '/request-product',
  },

  cptCode: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/insurance-cpt-code?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/insurance-cpt-code',
    update: (id: string) => {
      return `/insurance-cpt-code/${id}`;
    },
    delete: (id: string) => {
      return `/insurance-cpt-code/${id}`;
    },
  },

  authType: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/insurance-auth-rule?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/insurance-auth-rule',
    update: (id: string) => {
      return `/insurance-auth-rule/${id}`;
    },
    delete: (id: string) => {
      return `/insurance-auth-rule/${id}`;
    },
  },

  noteType: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/insurance-note-type?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/insurance-note-type',
    update: (id: string) => {
      return `/insurance-note-type/${id}`;
    },
    delete: (id: string) => {
      return `/insurance-note-type/${id}`;
    },
  },

  diagnasis: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/insurance-dx?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/insurance-dx',
    update: (id: string) => {
      return `/insurance-dx/${id}`;
    },
    delete: (id: string) => {
      return `/insurance-dx/${id}`;
    },
  },

  insuranceEobStorage: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/insurance-eob-storage?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/insurance-eob-storage',
    update: (id: string) => {
      return `/insurance-eob-storage/${id}`;
    },
    delete: (id: string) => {
      return `/insurance-eob-storage/${id}`;
    },
  },

  insuranceBilling: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/insurance-billing?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/insurance-billing',
    update: (id: string) => {
      return `/insurance-billing/${id}`;
    },
    delete: (id: string) => {
      return `/insurance-billing/${id}`;
    },
  },

  insuranceLimitation: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/insurance-limitation?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/insurance-limitation',
    update: (id: string) => {
      return `/insurance-limitation/${id}`;
    },
    delete: (id: string) => {
      return `/insurance-limitation/${id}`;
    },
  },

  insurance: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/insurance?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/insurance',
    update: (id: string) => {
      return `/insurance/${id}`;
    },
    delete: (id: string) => {
      return `/insurance/${id}`;
    },
    detail: (id: string) => {
      return `/insurance/${id}`;
    },
    faqAdd: (id: string) => {
      return `/insurance-faq-add/${id}`;
    },
    faqUpdate: (id: string) => {
      return `/insurance-faq-edit/${id}`;
    },
    faqDelete: (id: string) => {
      return `/insurance-faq-delete/${id}`;
    },
  },

  insVerification: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/insVerification?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/insVerification',
    update: (id: string) => {
      return `/insVerification/${id}`;
    },
    delete: (id: string) => {
      return `/insVerification/${id}`;
    },
    detail: (id: string) => {
      return `/insVerification/${id}`;
    },
    faqAdd: (id: string) => {
      return `/insVerification-faq-add/${id}`;
    },
    faqUpdate: (id: string) => {
      return `/insVerification-faq-edit/${id}`;
    },
    faqDelete: (id: string) => {
      return `/insVerification-faq-delete/${id}`;
    },
  },

  authorizationNotes: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/authorization-notes?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/authorization-notes',
    update: (id: string) => {
      return `/authorization-notes/${id}`;
    },
    delete: (id: string) => {
      return `/authorization-notes/${id}`;
    },
  },

  precaution: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/permanent-precaution?page=${page}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/permanent-precaution',
    update: (id: string) => {
      return `/permanent-precaution/${id}`;
    },
    delete: (id: string) => {
      return `/permanent-precaution/${id}`;
    },
  },

  pcp: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/pcp-details?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/pcp-details',
    update: (id: string) => {
      return `/pcp-details/${id}`;
    },
    delete: (id: string) => {
      return `/pcp-details/${id}`;
    },
  },

  privateFee: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/private-fee?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/private-fee',
    update: (id: string) => {
      return `/private-fee/${id}`;
    },
    delete: (id: string) => {
      return `/private-fee/${id}`;
    },
    changeStatus: (id: string) => {
      return `/private-fee/${id}/status`;
    },
  },

  pocDays: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/poc-days?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/poc-days',
    update: (id: string) => {
      return `/poc-days/${id}`;
    },
    delete: (id: string) => {
      return `/poc-days/${id}`;
    },
  },

  fds: {
    addMemo: (id: string) => {
      return `/patient-fds-memo/${id}`;
    },
    addNote: (id: string) => {
      return `/fds-daily-notes/${id}`;
    },
    dailyEntry: (id: string) => {
      return `/fds-daily-entry/${id}`;
    },
    auth_request: () => {
      return `/auth-request`;
    },
    auth_request_schedule: () => {
      return `/auth-request?isScheduled=true`;
    },

    auth_tracker: (id: any) => {
      return `/auth-tracker-claim?ctId=${id}`;
    },
    auth_approved: (id: any) => {
      return `/auth-request-approved/${id}`;
    },
    auth_schedule_decline: (id: any) => {
      return `/auth-schedule-decline/${id}/actionType`;
    },
    create_memo_request: () => {
      return `/message-center`;
    },
    save_to_be_scheduled: (id: any) => {
      return `/request-close-pending/${id}`;
    },
  },
  deductableCollect: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/deductable-collect?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/deductable-collect',
    update: (id: string) => {
      return `/deductable-collect/${id}`;
    },
    delete: (id: string) => {
      return `/deductable-collect/${id}`;
    },
  },

  exercise: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/exercise?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/exercise',
    update: (id: string) => {
      return `/exercise/${id}`;
    },
    delete: (id: string) => {
      return `/exercise/${id}`;
    },
  },
  newsLetter: {
    list: (page: number, limit: number, search: string) => {
      return (
        `/news-letter?page=${page}&limit=${limit}` +
        (search ? `&search=${search}` : '')
      );
    },
    add: '/news-letter',
    update: (id: string) => {
      return `/news-letter/${id}`;
    },
    delete: (id: string) => {
      return `/news-letter/${id}`;
    },
  },
};
